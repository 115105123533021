import { MachineError } from '../models/error';
import { ModuleType } from './core.state';

export namespace Core {
  export class Ping {
    static readonly type = '[Core] Ping';
    constructor() {}
  }

  export class LoadModules {
    static readonly type = '[Core] LoadModules';
    constructor() {}
  }

  export class RegisterModule {
    static readonly type = '[Core] RegisterModule';
    constructor(public module: ModuleType) {}
  }

  export class Error {
    static readonly type = '[Core] Error';
    constructor(public machineError: MachineError, public module: ModuleType) {}
  }

  export class Fault {
    static readonly type = '[Core] Fault';
    constructor(public machineError: MachineError, public module: ModuleType) {}
  }

  export class StartPayment {
    static readonly type = '[Core] StartPayment';
    constructor(public amount: number) {}
  }

  export class FinishPayment {
    static readonly type = '[Core] FinishPayment';
    constructor(public amount: number) {}
  }

  export class CancelPayment {
    static readonly type = '[Core] CancelPayment';
    constructor() {}
  }

  export class Print {
    static readonly type = '[Core] Print';
    constructor(public data: string) {}
  }
  export class PrintV2 {
    static readonly type = '[Core] PrintV2';
    constructor(public data: string) {}
  }

  export class PrintPreorder {
    static readonly type = '[Core] PrintPreorder';
    constructor(public data: any) {}
  }

  export class LoadWebConfig {
    static readonly type = '[Core] LoadWebConfig';
    constructor() {}
  }

  export class LoadMachineData {
    static readonly type = '[Core] LoadMachineData';
    constructor() {}
  }

  export class CheckCardNumber {
    static readonly type = '[Core] CheckCardNumber';
    constructor(public cardNumber: string) {}
  }

  export class ReloadLockerData {
    static readonly type = '[Core] ReloadLockerData';
    constructor() {}
  }

  export class ExternalPaymentRequest {
    static readonly type = '[Core] ExternalPaymentRequest';
    constructor(public paymentData: any) {}
  }

  export class ExternalOpenHatch {
    static readonly type = '[Core] ExternalOpenHatch';
    constructor() {}
  }

  export class ExternalOpenLocker {
    static readonly type = '[Core] ExternalOpenLocker';
    constructor(public slotData: any) {}
  }
}

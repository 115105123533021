import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { VendingTypes } from 'src/app/retailer/state/retailer.state';
import { MachineError } from '../models/error';
import { ControlService } from '../services/control.service';
import { Control } from './control.actions';
import { CoreStateModel, CoreState } from './core.state';

@State<void>({
  name: 'control',
})
@Injectable()
export class ControlState {
  constructor(private controlService: ControlService, private store: Store) {}

  @Action(Control.OpenHatch)
  openHatch(ctx: StateContext<void>) {
    //check if it is WWKS to use terminal API
    let vendingType =
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.vendingType;
    if (vendingType == VendingTypes.WWKS2) {
      return this.controlService.terminalOpenHatch().pipe(
        catchError((error: MachineError) => {
          return throwError(error);
        }),
        switchMap(() => {
          return this.controlService.terminalCheckHatch().pipe(
            catchError((error: MachineError) => {
              return throwError(error);
            })
          );
        })
      );
    } else {
      return this.controlService.openHatch().pipe(
        catchError((error: MachineError) => {
          return throwError(error);
        })
      );
    }
  }
}

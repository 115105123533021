import { Injectable } from '@angular/core';
import { Action, createSelector, NgxsAfterBootstrap, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ControlService } from '../services/control.service';
import { Token } from './token.actions';

//KeyValueStorage
@State<any>({
  name: 'storage',
  defaults: {
    'token': 'fxYn9jSSz3N0lk9wbDLSZulokKBEsTuPug5Ypaz4QQBoX7Bp1Ef1zttvwE5OKl7d'
  }
})
@Injectable()
export class TokenState implements NgxsAfterBootstrap {

  constructor(private controlService: ControlService) {}

  ngxsAfterBootstrap(ctx?: StateContext<any>): void {
    this.controlService.machineToken().subscribe(token => {
      ctx.patchState({
        token: token
      });
    })
  }

  static get(key: string): any {
    return createSelector([TokenState], (state: any) => {
      return state[key];
    })
  }

  @Action(Token.Set)
  set(ctx: StateContext<any>, action: Token.Set) {
    let changes = {};
    changes[action.key] = action.value;
    ctx.setState(patch(changes));
  }
}
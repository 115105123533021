import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { CoreState, CoreStateModel } from 'src/app/core/state/core.state';
import { WwksService } from '../../services/wwks.service';
import {
  ControlCode,
  ControlService,
} from 'src/app/core/services/control.service';
import { delay, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MqttHelperService } from 'src/app/core/services/mqtt.service';
import { error } from 'protractor';

@Component({
  selector: 'app-retrieve-wwks',
  templateUrl: './retrieve-wwks.component.html',
  styleUrls: ['./retrieve-wwks.component.scss'],
})
export class RetrieveWwksComponent implements OnInit {
  public gifSrc: string;
  theme: string;
  finished: boolean = false;
  fullHdHalf: boolean = false;
  fullHd: boolean = false;
  showThanksForPurchase: boolean = true;
  intervalId: any;
  hatchInterval: any;
  countdownFull = 30;
  countdown = 30;
  source: string;

  constructor(
    private store: Store,
    private wwksService: WwksService,
    private controlService: ControlService,
    private route: ActivatedRoute,
    private mqttHelperService: MqttHelperService
  ) {
    this.route.params.subscribe((params) => {
      this.source = params.source;

      if (this.source === 'wwks') {
        this.controlService.terminalOpenHatch().subscribe({
          next: () => {
            console.log('hatch opened');
          },
          error: (err) => {
            console.log('error');
            console.log(err);
          },
        });
        this.checkHatch();
        this.hatchInterval = setInterval(() => {
          this.checkHatch();
        }, 2000);
      } else if (this.source === 'mqtt') {
        this.mqttHelperService.startAndMonitorOuttake(false).subscribe({
          next: () => {
            console.log('hatch unlocked');
          },
          error: (err) => {
            console.log('error');
            console.log(err);
          },
        });
      }
    });

    this.theme = localStorage.getItem('theme') ?? 'default';
    this.fullHdHalf = JSON.parse(localStorage.getItem('fullHdHalf')) ?? false;
    this.fullHd = JSON.parse(localStorage.getItem('fullhd')) ?? false;

    if (
      this.store.selectSnapshot<CoreStateModel>(CoreState).config.isOfficeButler
    ) {
      this.showThanksForPurchase = false;
    }

    this.gifSrc = './assets/counter.gif?a=' + Math.random();

    this.intervalId = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        console.log('finished', this.finished);
        this.skipTimerOnClick();
      }
    }, 1000);
  }

  checkHatch() {
    this.controlService.terminalCheckHatchOnce().subscribe((res) => {
      console.log(res);
      if (res.controlError == ControlCode.SUCCESS) {
        console.log('hatch closed');
        if (this.wwksService.purchaseFinishedCB) {
          this.wwksService.purchaseFinishedCB();
        }
        clearInterval(this.hatchInterval);
        clearInterval(this.intervalId);
        this.finished = true;
      }
    });
  }

  ngOnInit(): void {}

  skipTimerOnClick() {
    console.log('finished clicked', this.finished);

    clearInterval(this.intervalId);
    clearInterval(this.hatchInterval);
    this.finished = true;

    if (this.source === 'wwks') {
      this.controlService.terminalCloseHatch().subscribe({
        next: () => {
          console.log('hatch closed');
          if (this.wwksService.purchaseFinishedCB) {
            this.wwksService.purchaseFinishedCB();
          }
        },
        error: (err) => {
          console.log('error');
          console.log(err);
          if (this.wwksService.purchaseFinishedCB) {
            this.wwksService.purchaseFinishedCB();
          }
        },
      });
    } else if (this.source === 'mqtt') {
      this.mqttHelperService.lockHatch().subscribe({
        next: () => {
          console.log('hatch closed');
          if (this.wwksService.purchaseFinishedCB) {
            this.wwksService.purchaseFinishedCB();
          }
        },
        error: (err) => {
          console.log('error');
          console.log(err);
          if (this.wwksService.purchaseFinishedCB) {
            this.wwksService.purchaseFinishedCB();
          }
        },
      });
    } else {
      if (this.wwksService.purchaseFinishedCB) {
        this.wwksService.purchaseFinishedCB();
      }
    }
  }
}

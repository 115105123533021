export const environment = {
  production: true,
  api: '/rest',
  imageApi: 'filedownload?fileName=',
  configApi: '/api/v1',
  websocketApi: 'wss://app.derautomat.com',
  paymentApi: 'http://localhost:8888',
  controlApi: 'http://localhost:8889',
  printerApi: 'http://localhost:9000',
  locale: 'de',
  wwksApi: 'http://localhost:8890',
  appIdle: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
